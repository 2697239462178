@import 'typography';
@import 'colors';
@import 'sizes';

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: PrimaryFont, sans-serif;
  border-radius: 28px;
  border: 2px solid transparent;
  text-align: center;
  transition: transform 0.1s;
  cursor: pointer;
  padding: map-get($spaces, 400) map-get($spaces, 700);
  line-height: 14px;
  height: 56px;
}

.primary-button {
  @extend .button;
  @extend .text-small;
  background: var(--z-cta-buttons-primary-background-color, map-get($color-accents, 1));
  color: var(--z-cta-buttons-primary-text-color, $color-neutral);
}

.secondary-button {
  @extend .button;
  @extend .text-small;
  background: var(--z-q-card-navigation-buttons-background-color, map-get($color-accents, 1));
  color: var(--z-q-card-navigation-buttons-icon-color, $color-neutral);
}

.tertiary-button {
  @extend .button;
  @extend .text-small;
  background: transparent;
  border-color: var(--z-cta-buttons-outline-background-color, map-get($color-accents, 1));
  color: var(--z-cta-buttons-outline-text-color, $color-neutral);
}

.primary-button:disabled,
.secondary-button:disabled,
.tertiary-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
  background-color: var(--z-cta-buttons-disabled-background-color, map-get($color-accents, 1));
  color: var(--z-cta-buttons-disabled-text-color, $color-neutral);
}

.transparent-btn {
  border: 0;
  background-color: transparent;
  cursor: pointer;
  height: auto;

  .mat-icon {
    color: $color-neutral;
    opacity: 0.5;
  }
}

.mobile-version {
  .button {
    padding: map-get($spaces, 300) map-get($spaces, 700);
  }
}
