// Baseline
$color-base: (
  1: var(--color-base-1, #3C423C), // lightest
  2: var(--color-base-2, #292F2A),
  3: var(--color-base-3, #1A1E1A),
  4: var(--color-base-4, #000000), // darkest
);

// Accent
$color-accents: (
  1: var(--color-accents-1, #39F56A),
  2: var(--color-accents-2, #C0F9C8),
  3: var(--color-accents-3, #C3FF00)
);

$color-neutral: var(--color-neutral, #FFFFFF);
$color-neutral-2: #FFFFFF; // don't exist on the backend, just here for the elements which are always white

$color-success: var(--color-success, #00C76B);
$color-warning: var(--color-warning, #F9AE41);
$color-error: var(--color-error, #F64B4B);