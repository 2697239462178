@import "colors";

.invisible-scroll {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
  }
}

.uppercase {
  text-transform: uppercase;
}

.cursor {
  cursor: pointer;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 70px 0;
}

// Layout styles
.lt-flex {
  display: flex;
}

.lt-column {
  flex-direction: column;
}

.lt-row {
  flex-direction: row;
}

.lt-align-center {
  align-items: center;
}

.lt-justify-space-between {
  justify-content: space-between;
}

.lt-justify-center {
  justify-content: center;
}
