@import "colors";
@import "typography";

// Selector
.custom-select-wrapper {
  position: relative;
  margin-top: 16px;
}

.custom-select-wrapper::after {
  content: '';
  width: 24px;
  height: 24px;
  background-image: url('../../assets/icons/Chevron-Down-Green.svg');
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
}

.custom-select {
  @extend .text-body-1;
  position: relative;
  z-index: 1;
  border-radius: 8px;
  border: 0;
  background: rgba(var(--color-neutral-rgb), 0.1);
  padding: 14px 16px;
  width: 100%;
  color: $color-neutral;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-select:hover {
  cursor: pointer;
}

.custom-select:focus {
  outline: none;
}

// for Microsoft browsers only
@supports (-ms-ime-align: auto) {
  .custom-select {
    background-color: map-get($color-base, 3);
  }
}
