@import 'colors';

// Angular material styles
@import '@angular/material/prebuilt-themes/indigo-pink.css';

// global styles
@import 'typography';
@import 'buttons';
@import 'form';
@import 'ui';
@import 'custom-material';

* {
  box-sizing: border-box;
  user-select: none;
}

body,
html {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  color: $color-neutral;
}

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  background: rgba(var(--color-neutral-rgb), 0.1);
}

::-webkit-scrollbar-corner {
    background: rgba(var(--color-neutral-rgb), 0.1);
}

/* For Webkit browsers like Chrome, Safari */

::-webkit-scrollbar-thumb {
  background: rgba(var(--color-neutral-rgb), 0.25);
  border-radius: 10px;
  height: 10px;
}

@-moz-document url-prefix() {
  * {
    scrollbar-width: auto;
    scrollbar-color: rgba(var(--color-neutral-rgb), 0.1) rgba(var(--color-neutral-rgb), 0.25);
  }
}

.hidden {
  visibility: hidden;
}

.app-container {
  max-width: 1920px;
  margin: 0 auto;
  padding: 20px 40px;
}

.mobile-version {
  app-root {
    align-items: start;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
select,
option,
div {
  margin: 0;
  font-family: PrimaryFont, sans-serif;
}

.route {
  font-family: PrimaryFont, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  border: none;
  text-align: center;
  transition: transform 0.1s;
  cursor: pointer;
  background: transparent;
  color: $color-neutral;
  font-size: 12px;
  padding: 10px 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 1px;
  text-decoration: none;
}

@media (min-width: 768px) {
  .route {
    font-size: 14px;
    line-height: 17.5px;
  }
}

.mat-mdc-progress-spinner circle {
  stroke: map-get($color-accents, 2);
}