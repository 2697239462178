@import 'colors';

.mat-mdc-progress-spinner circle {
  stroke: rgba(var(--color-neutral-rgb), 0.3);
}

.users-list {
  .cdk-virtual-scroll-spacer {
    // fix for some strange issue related to virtual scroll
    margin-top: -110px;
  }
}

.results-tabs {
  height: 100%;

  .mat-mdc-tab .mdc-tab__text-label {
    text-transform: uppercase;
    letter-spacing: normal;
    font-size: 27px;
    font-weight: 700;
    color: rgba(var(--color-neutral-rgb), 0.25);
  }

  .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label,
  .mat-mdc-tab.mdc-tab--active:hover .mdc-tab__text-label,
  .mat-mdc-tab.mdc-tab--active:focus .mdc-tab__text-label,
  .mat-mdc-tab:focus .mdc-tab__text-label,
  .mat-mdc-tab:hover .mdc-tab__text-label {
    color: $color-neutral;
  }

  .mdc-tab {
    padding-left: 0;
  }

  .mdc-tab-indicator .mdc-tab-indicator__content, .mat-mdc-tab-ripple {
    display: none;
  }

  .mat-mdc-tab:hover .mdc-tab__ripple::before,
  .mat-mdc-tab:focus .mdc-tab__ripple::before {
    opacity: 0;
  }

  .mat-mdc-tab-body-wrapper {
    height: 100%;
  }
}



